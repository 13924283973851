<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto rounded-lg" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL DESARMONÍAS REPORTADAS</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{dashboardData.total_cases}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-account-injury</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">CASOS EN INVESTIGACIÓN</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{(dashboardData.total_by_state?.investigacion || '')}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-eye</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">CASOS PENDIENTES DE ASIGNACION</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{(dashboardData.total_by_state?.inicial || '')}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-account-check</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="pa-5">
            <v-toolbar flat>
              <v-toolbar-title>ULTIMOS EVENTOS INFORMADOS</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark rounded class="mb-2" :to="{
                name:'selfjustice.listofdisharmonies'
              }">
                Ver todos
              </v-btn>
            </v-toolbar>
            <v-row>
              <v-col cols="12">
                <v-data-table
                    :footer-props="{
                    'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]

                  }"
                    :headers="headers"
                    :items="dishamories"
                    :items-per-page="5"
                    class="elevation-1"
                    :server-items-length="filtrado?.count"
                    v-on:update:options="onPaginate"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                >
                  <template v-slot:item.state="{ item }">
                    <v-chip outlined color="primary">
                      {{ item.state }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import {censusStats} from "@/services/dataSource";
  import {getDashboardData, listDisharmony} from "@/services/SelfJustice";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      censusStats: [],
      dishamories: [],
      cardValues: [],
      dashboardData : {},
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "TIPO DE DESARMONÍA", value: "disharmony", sortable: false },
        { text: "TERRITORIO", value: "territory", sortable: false },
        { text: "FECHA", value: "reception_datetime" },
        { text: "T.RECEPCIÓN", value: "reception_type", sortable: false },
      ],
      filtrado: {},
      sortBy: 'id',
      sortDesc: false,
    };
  },

  methods: {
    showModalEditUser(id) {
      this.$refs.modalEditUser.openDialog(id);
    },
    showModalView(id) {
      this.$refs.modalEstadoUser.openDialog(id);
    },
    getCurrentFilter() {
      return {
        ...(this.search && { search: this.search }),
      };
    },
    onPaginate: async function (
      pagination = { sortBy: [], sortDesc: [], itemsPerPage: 15, page: 1 }
    ) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.currentItemsPerPage = itemsPerPage;
      this.filtrado = await this.list({
        page,
        itemsPerPage,
        ...this.getCurrentFilter(),
        ...this.filters,
        ...(sortBy[0] && {
          [`${sortBy[0]}`]: sortDesc[0],
        }),
      });
    },
    async list(options) {
      const listDishamoryResponse = (await listDisharmony(options))?.data;
      this.dishamories = listDishamoryResponse?.results.map((x) => {
        return {
          id: x.id,
          disharmony: x.disharmony,
          territory: x.territory,
          date: x.date,
          reception_datetime: x.reception_datetime,
          jurisdiction: x.jurisdiction,
          reception_type: x.reception_type,
          municipality: x.municipality,
        };
      });

      return listDishamoryResponse;
    },
  },
  async created() {
    try{
      this.showLoader();
      this.dashboardData = (await getDashboardData())?.data
      this.hideLoader();
    }catch (e) {
      this.hideLoader()
      console.error(e);
      Swal.fire('ha ocurrido un erorr', '', 'error');
    }
  },
};
</script>
